import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Stack from '../components/systems/stack';
import SEO from '../components/layout/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Geschäftsbedingungen" shouldIndex={false} mdxType="SEO" />
    <Stack gap="20px" centered={false} mdxType="Stack">
      <h1>{`ALLGEMEINE GESCHÄFTSBEDINGUNGEN MIT KUNDENINFORMATIONEN`}</h1>
      <p><strong parentName="p">{`Inhaltsverzeichnis`}</strong></p>
      <ol>
        <li parentName="ol">{`Geltungsbereich`}</li>
        <li parentName="ol">{`Leistungen des Anbieters`}</li>
        <li parentName="ol">{`Vertragsschluss`}</li>
        <li parentName="ol">{`Widerrufsrecht`}</li>
        <li parentName="ol">{`Preise und Zahlungsbedingungen`}</li>
        <li parentName="ol">{`Inhalte der Leistungen und Lehrmaterial`}</li>
        <li parentName="ol">{`Einräumung von Nutzungsrechten an digitalen Inhalten 	und Überlassung`}</li>
        <li parentName="ol">{`Mängelhaftung`}</li>
        <li parentName="ol">{`Haftung`}</li>
        <li parentName="ol">{`Anwendbares Recht`}</li>
        <li parentName="ol">{`Alternative Streitbeilegung`}</li>
      </ol>
      <h2>{`1) Geltungsbereich`}</h2>
      <p><strong parentName="p">{`1.1`}</strong>{`  Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") der Webseite von Beate Landgraf (nachfolgend "Anbieter"), gelten für alle Verträge über die Teilnahme an Online-Seminare und die Lieferung von digitalen Inhalten (nachstehend „Leistungen“), die ein Verbraucher oder Unternehmer (nachfolgend „Kunde“) mit dem Anbieter hinsichtlich der auf der Website des Anbieters dargestellten Leistungen abschließt.
Hiermit wird der Einbeziehung von eigenen Bedingungen des Kunden widersprochen, es sei denn, es ist etwas anderes vereinbart.`}</p>
      <p><strong parentName="p">{`1.2 `}</strong>{` Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt. Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.`}</p>
      <p><strong parentName="p">{`1.3`}</strong>{` Digitale Inhalte im Sinne dieser AGB sind alle nicht auf einem körperlichen Datenträger befindlichen Daten, die in digitaler Form hergestellt und vom Anbieter unter Einräumung bestimmter in diesen AGB genauer geregelten Nutzungsrechte bereitgestellt werden.`}</p>
      <h2>{`2) Leistungen des Anbieters`}</h2>
      <p><strong parentName="p">{`2.1`}</strong>{` Der Anbieter liefert über seine Website digitale Inhalte. Deren Gegenstand ergibt sich aus der jeweiligen Leistungsbeschreibung auf der Website des Anbieters.`}</p>
      <p><strong parentName="p">{`2.2 `}</strong>{` Der Anbieter erbringt seine Leistungen durch qualifiziertes, von ihm ausgewähltes Personal. Dabei kann sich der Anbieter auch der Leistungen Dritter (Subunternehmer) bedienen, die in seinem Auftrag tätig werden. Sofern sich aus den Beschreibungen des Anbieters nichts anderes ergibt, hat der Kunde keinen Anspruch auf Auswahl einer bestimmten Person zur Erbringung der beauftragten Leistung.`}</p>
      <p><strong parentName="p">{`2.3`}</strong>{`  Der Anbieter erbringt seine Leistungen mit größter Sorgfalt und nach bestem Wissen und Gewissen. Einen bestimmten Erfolg schuldet der Anbieter aber nicht. Insbesondere übernimmt der Anbieter keine Gewähr dafür, dass sich beim Kunden ein bestimmter Lernerfolg einstellt oder dass der Kunde ein bestimmtes Leistungsziel erreicht. Dies ist nicht zuletzt auch vom persönlichen Einsatz und Willen des Kunden abhängig, auf den der Anbieter keinen Einfluss hat.`}</p>
      <p><strong parentName="p">{`2.4 `}</strong>{` Soweit der Anbieter digitale Inhalte liefert, erfolgt die Lieferung ausschließlich über die Bereitstellung eines Online-Video-Streams unter Einsatz entsprechender technischer Mittel. Für die ordnungsgemäße Reproduktion des Streams muss das System des Kunden bestimmte Mindestvoraussetzungen erfüllen, die dem Kunden auf der Website des Anbieters mitgeteilt werden. Für die Einhaltung der Systemvoraussetzungen trägt der Kunde die Verantwortung. Der Anbieter haftet nicht für technische Probleme, die auf mangelhafte Systemvoraussetzungen beim Kunden zurückzuführen sind.`}</p>
      <h2>{`3) Vertragsschluss`}</h2>
      <p><strong parentName="p">{`3.1`}</strong>{` Die auf der Website des Anbieters beschriebenen Leistungen stellen keine verbindlichen Angebote seitens des Anbieters dar, sondern dienen zur Abgabe eines verbindlichen Angebots durch den Kunden.`}</p>
      <p><strong parentName="p">{`3.2 `}</strong>{`Der Kunde kann sein Angebot über das auf der Website des Anbieters bereitgestellte Online-Formular abgeben. Dabei gibt der Kunde, nachdem er seine Daten in das Formular eingetragen hat, durch Klicken des den Anmeldevorgang abschließenden Buttons ein rechtlich verbindliches Vertragsangebot in Bezug auf die ausgewählte Leistung ab.`}</p>
      <p><strong parentName="p">{`3.3`}</strong>{` Der Anbieter kann das Angebot des Kunden innerhalb von fünf Tagen annehmen,`}</p>
      <ul>
        <li parentName="ul">{`indem er dem Kunden eine schriftliche Anmeldebestätigung oder eine Anmeldebestätigung in Textform (E-Mail) übermittelt, wobei insoweit der Zugang der Anmeldebestätigung beim Kunden maßgeblich ist, oder`}</li>
        <li parentName="ul">{`indem er den Kunden nach Abgabe von dessen Vertragserklärung zur Zahlung auffordert`}</li>
      </ul>
      <p>{`Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in dem Zeitpunkt zustande, in dem eine der vorgenannten Alternativen zuerst eintritt. Nimmt der Anbieter das Angebot des Kunden innerhalb vorgenannter Frist nicht an, so gilt dies als Ablehnung des Angebots mit der Folge, dass der Kunde nicht mehr an seine Willenserklärung gebunden ist. Im Falle der Abhaltung eines Online-Live-Kurses gilt Gleiches für den Fall, dass der vom Kunden ausgewählte Kurs schon vor Ablauf der Annahmefrist beginnt und der Anbieter das Angebot des Kunden nicht bis spätestens 24 Stunden vor Beginn des Kurses annimmt, sofern zwischen den Parteien nichts anderes vereinbart wurde.`}</p>
      <p><strong parentName="p">{`3.4`}</strong>{`  Bei der Abgabe eines Angebots über das Online-Formular des Anbieters wird der Vertragstext nach dem Vertragsschluss vom Anbieter gespeichert und dem Kunden nach Absendung von dessen Angebot in Textform (z. B. E-Mail oder Brief) übermittelt. Eine darüberhinausgehende Zugänglichmachung des Vertragstextes durch den Anbieter erfolgt nicht. Sofern der Kunde vor Absendung des Angebots ein Nutzerkonto auf der Website des Anbieters eingerichtet hat, werden die Daten über die beauftragte Leistung auf der Website des Anbieters archiviert und können vom Kunden über dessen passwortgeschütztes Nutzerkonto unter Angabe der entsprechenden Login-Daten kostenlos abgerufen werden.`}</p>
      <p><strong parentName="p">{`3.5`}</strong>{` Vor verbindlicher Abgabe des Angebots über das Online-Formular kann der Kunde seine Eingaben laufend über die üblichen Tastatur- und Mausfunktionen korrigieren. Darüber hinaus werden alle Eingaben vor der verbindlichen Abgabe des Angebots noch einmal in einem Bestätigungsfenster angezeigt und können auch dort mittels der üblichen Tastatur- und Mausfunktionen korrigiert werden.`}</p>
      <p><strong parentName="p">{`3.6 `}</strong>{` Für den Vertragsschluss steht ausschließlich die deutsche Sprache zur Verfügung.`}</p>
      <p><strong parentName="p">{`3.7`}</strong>{` Kontaktaufnahmen des Anbieters zur Vertragsabwicklung finden in der Regel per E-Mail statt. Der Kunde hat sicherzustellen, dass die von ihm bei der Abgabe des Angebots angegebene E-Mail-Adresse zutreffend ist, sodass unter dieser Adresse die vom Anbieter versandten E-Mails empfangen werden können. Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern sicherzustellen, dass alle vom Anbieter oder von diesem mit der Vertragsabwicklung beauftragten Dritten versandten E-Mails zugestellt werden können.`}</p>
      <h2>{`4) Widerrufsrecht`}</h2>
      <p>{`Nähere Informationen zum Widerrufsrecht ergeben sich aus der Widerrufsbelehrung des Anbieters.`}</p>
      <h2>{`5) Preise und Zahlungsbedingungen`}</h2>
      <p><strong parentName="p">{`5.1`}</strong>{` Sofern sich aus den Leistungsbeschreibungen des Anbieters nichts anderes ergibt, handelt es sich bei den angegebenen Preisen um Gesamtpreise, die die gesetzliche Umsatzsteuer enthalten.`}</p>
      <p><strong parentName="p">{`5.2`}</strong>{` Dem Kunden stehen verschiedene Zahlungsmöglichkeiten zur Verfügung, die auf der Website des Anbieters angegeben werden.`}</p>
      <p><strong parentName="p">{`5.3`}</strong>{` Bei Zahlungen in Ländern außerhalb der Europäischen Union können im Einzelfall weitere Kosten anfallen, die der Anbieter nicht zu vertreten hat und die vom Kunden zu tragen sind. Hierzu zählen beispielsweise Kosten für die Geldübermittlung durch Kreditinstitute (z.B. Überweisungsgebühren, Wechselkursgebühren).`}</p>
      <p><strong parentName="p">{`5.4`}</strong>{` Bei den ausdrücklich als Präventionskurs gekennzeichneten Kursen handelt es sich um ein zertifiziertes Präventionsangebot. Die Kosten für diesen Kurs können unter den Voraussetzungen des § 20 SGB V von gesetzlichen Krankenkassen rückwirkend ganz oder teilweise an den Kunden zurückerstattet werden. Für eine vollständige oder teilweise Rückerstattung müssen folgende Voraussetzungen erfüllt werden:`}</p>
      <ul>
        <li parentName="ul">{`Vollständige, erfolgreiche Teilnahme an allen Kursstunden und der Ausstellung einer Teilnahmebescheinigung nach Abschluss des Kurses.`}</li>
        <li parentName="ul">{`Der Kunde muss bestätigen, dass er nicht an Vorerkrankungen leidet, welche die Teilnahme als Präventionsmaßnahme nach § 20 SGB V ausschließen. Die Kostenerstattung nach § 20 SGB V findet zwischen der jeweiligen Krankenkasse und dem Kunden statt. Abgesehen von der Erteilung einer Teilnahmebescheinigung nach erfolgreichem Abschluss des Kurses ist es dem Anbieter nicht möglich, auf diesen Prozess Einfluss zu nehmen. Dem Kunden wird ausdrücklich empfohlen, die Erstattung vorab und direkt mit seiner Krankenkasse abzuklären.`}</li>
      </ul>
      <p>{`Kunden, welche für Leistungen von dem Anbieter als Versicherte einer Krankenkasse von dieser eine kostenfreie Teilnahme ermöglicht bekommen, haben sich gegebenenfalls im Verhältnis zu ihrer Krankenkasse verpflichtet, entsprechende Voraussetzungen zu erfüllen. Dabei gilt zu beachten, dass bei einer Nichterfüllung der Voraussetzungen die jeweilige Krankenkasse die Kosten möglicherweise nicht übernimmt oder rückwirkend gegenüber dem Kunden geltend macht. Auf dieses Verhältnis zwischen Krankenkasse und versicherten Kunden hat der Anbieter ausdrücklich keinen Einfluss.`}</p>
      <h2>{`6) Inhalte der Leistungen und Lehrmaterial`}</h2>
      <p><strong parentName="p">{`6.1`}</strong>{` Der Anbieter ist Inhaber sämtlicher Nutzungsrechte, die zur Erbringung der Leistungen erforderlich sind. Dies gilt auch im Hinblick auf Lehrunterlagen, die dem Kunden gegebenenfalls im Zusammenhang mit der Leistungserbringung überlassen werden.`}</p>
      <p><strong parentName="p">{`6.2`}</strong>{` Etwaiges leistungsbegleitendes Lehrmaterial (z. B. Lehrunterlagen) wird dem Kunden ausschließlich in elektronischer Form per E-Mail oder zum Download zur Verfügung gestellt. Sofern nichts anderes vereinbart ist, hat der Kunde keinen Anspruch auf Überlassung des Lehrmaterials in körperlicher Form.`}</p>
      <p><strong parentName="p">{`6.3`}</strong>{` Der Kunde darf die Inhalte der Leistungen einschließlich gegebenenfalls überlassener Lehrunterlagen lediglich in dem Umfang nutzen, der nach dem von beiden Parteien zugrunde gelegten Vertragszweck erforderlich ist. Ohne gesonderte Erlaubnis des Anbieters ist der Kunde insbesondere nicht berechtigt, Inhalte der Leistungen oder Teile daraus aufzuzeichnen oder Lehrunterlagen zu vervielfältigen, zu verbreiten oder öffentlich zugänglich zu machen.`}</p>
      <h2>{`7) Einräumung von Nutzungsrechten an digitalen Inhalten und Überlassung`}</h2>
      <p><strong parentName="p">{`7.1`}</strong>{` Die Überlassung von digitalen Inhalten erfolgt ausschließlich über die Bereitstellung eines Online-Video-Streams unter Einsatz entsprechender technischer Mittel.`}</p>
      <p><strong parentName="p">{`7.2`}</strong>{` Sofern sich aus den Inhaltsbeschreibungen auf der Website des Anbieters nichts anderes ergibt, räumt der Anbieter dem Kunden an den überlassenen digitalen Inhalten das nicht ausschließliche, örtlich und zeitlich unbeschränkte Recht ein, die überlassenen Inhalte ausschließlich zu privaten Zwecken zu nutzen.`}</p>
      <p><strong parentName="p">{`7.3`}</strong>{` Die Rechtseinräumung wird erst wirksam, wenn der Kunde die vertraglich geschuldete Vergütung vollständig geleistet hat. Der Anbieter kann eine Benutzung der vertragsgegenständlichen Inhalte auch schon vor diesem Zeitpunkt vorläufig erlauben. Ein Übergang der Rechte findet durch eine solche vorläufige Erlaubnis nicht statt.`}</p>
      <p><strong parentName="p">{`7.4`}</strong>{` Die Voraussetzung für die Nutzung der Produkte ist eine gute gesundheitliche Verfassung. Bei psychologischen Erkrankungen oder anderen gesundheitlichen Beschwerden empfiehlt der Anbieter dringend, vor Beginn des Programms einen Arzt oder Psychotherapeuten zu konsultieren. Die Inhalte der Produkte ersetzen keine ärztliche Untersuchung oder Behandlung. Es handelt sich nicht um einen medizinischen Rat. Es besteht Eigenverantwortlichkeit, d.h. alle Informationen und Programminhalte werden auf eigenes Risiko genutzt. Der Kunde versichert, dass er an keinen behandlungsbedürftigen Erkrankungen der Psyche leidet. Liegen solche behandlungsbedürftigen Erkrankungen der Psyche vor, soll der Kunde einen Arzt kontaktieren, um sicherzustellen, dass dieser gefahrlos an dem Programm teilnehmen kann. Zudem soll der Kunde seine Krankenkasse kontaktieren, da eine Refinanzierung der Präventionskurse nicht sichergestellt werden kann. Der Kunde bestätigt die wahrheitsgemäße Angabe. Eine Haftung durch den Anbieter kann nicht übernommen werden. Die Inhalte der Trainingsvideos ersetzten keine ärztliche Untersuchung oder Behandlung. Es handelt sich nicht um medizinischen Rat. Vor Vertragsabschluss sollte der Kunde sich daher intensiv mit den Kontraindikationen befassen und im Zweifel ärztlichen Rat einholen.`}</p>
      <h2>{`8) Mängelhaftung`}</h2>
      <p>{`Es gilt die gesetzliche Mängelhaftung.`}</p>
      <h2>{`9) Haftung`}</h2>
      <p>{`Der Anbieter haftet dem Kunden aus allen vertraglichen, vertragsähnlichen und gesetzlichen, auch deliktischen Ansprüchen auf Schadens- und Aufwendungsersatz wie folgt:`}</p>
      <p><strong parentName="p">{`9.1`}</strong>{` Der Anbieter haftet aus jedem Rechtsgrund uneingeschränkt`}</p>
      <p>{`-bei Vorsatz oder grober Fahrlässigkeit,
-bei vorsätzlicher oder fahrlässiger Verletzung des Lebens, des Körpers oder der Gesundheit,
-aufgrund eines Garantieversprechens, soweit diesbezüglich nichts anderes geregelt ist,
-aufgrund zwingender Haftung wie etwa nach dem Produkthaftungsgesetz.`}</p>
      <p><strong parentName="p">{`9.2`}</strong>{` Verletzt der Anbieter fahrlässig eine wesentliche Vertragspflicht, ist die Haftung auf den vertragstypischen, vorhersehbaren Schaden begrenzt, sofern nicht gemäß vorstehender Ziffer unbeschränkt gehaftet wird. Wesentliche Vertragspflichten sind Pflichten, die der Vertrag dem Anbieter nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf.`}</p>
      <p><strong parentName="p">{`9.3`}</strong>{` Im Übrigen ist eine Haftung des Anbieters ausgeschlossen.`}</p>
      <p><strong parentName="p">{`9.4`}</strong>{` Vorstehende Haftungsregelungen gelten auch im Hinblick auf die Haftung des Anbieters für seine Erfüllungsgehilfen und gesetzlichen Vertreter.`}</p>
      <h2>{`10) Anwendbares Recht`}</h2>
      <p><strong parentName="p">{`10.1`}</strong>{` Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Gesetze über den internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.`}</p>
      <p><strong parentName="p">{`10.2`}</strong>{` Ferner gilt diese Rechtswahl im Hinblick auf das gesetzliche Widerrufsrecht nicht bei Verbrauchern, die zum Zeitpunkt des Vertragsschlusses keinem Mitgliedstaat der Europäischen Union angehören und deren alleiniger Wohnsitz und Lieferadresse zum Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union liegen.`}</p>
      <h2>{`11) Alternative Streitbeilegung`}</h2>
      <p><strong parentName="p">{`11.1`}</strong>{` Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit: `}<a parentName="p" {...{
          "href": "https://ec.europa.eu/consumers/odr"
        }}>{`https://ec.europa.eu/consumers/odr`}</a></p>
      <p>{`Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.`}</p>
      <p><strong parentName="p">{`11.2`}</strong>{` Der Verkäufer ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.`}</p>
      <h1>{`WIDERRUFSBELEHRUNG & WIDERRUFSFORMULAR`}</h1>
      <p>{`Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei Verbraucher jede natürliche Person ist, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können:`}</p>
      <h2>{`A. Widerrufsbelehrung`}</h2>
      <p><strong parentName="p">{`Widerrufsrecht`}</strong></p>
      <p>{`Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
Um Ihr Widerrufsrecht auszuüben, müssen Sie mir (Beate Landgraf, Lindenweg 36, 91091 Großenseebach, Deutschland, Tel.: +49 175 474 45 61‬, E-Mail: `}<a parentName="p" {...{
          "href": "mailto:mail@praxis-landgraf.de"
        }}>{`mail@praxis-landgraf.de`}</a>{`) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.‬‬
Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.`}</p>
      <p><strong parentName="p">{`Folgen des Widerrufs`}</strong></p>
      <p>{`Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
Erlöschen des Widerrufsrechts
Das Widerrufsrecht erlischt vorzeitig, wenn wir mit der Ausführung des Vertrages begonnen haben, nachdem Sie ausdrücklich zugestimmt haben, dass wir mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist beginnen und Sie uns Ihre Kenntnis davon bestätigt haben, dass Sie durch Ihre Zustimmung mit Beginn der Ausführung des Vertrags Ihr Widerrufsrecht verlieren.`}</p>
      <h2>{`B. Widerrufsformular`}</h2>
      <p>{`Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden es zurück.`}</p>
      <Stack centered={false} mdxType="Stack">
An
        <p>{`Beate Landgraf`}</p>
        <p>{`Lindenweg 36`}</p>
        <p>{`91091 Großenseebach`}</p>
        <p>{`Deutschland`}</p>
        <p>{`E-Mail: `}<a parentName="p" {...{
            "href": "mailto:mail@praxis-landgraf.de"
          }}>{`mail@praxis-landgraf.de`}</a></p>
      </Stack>
      <pre><code parentName="pre" {...{}}>{`Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
abgeschlossenen Vertrag über den Kauf der folgenden
Waren (*)/die Erbringung der folgenden Dienstleistung (*)
_______________________________________________________
_______________________________________________________
Bestellt am (*) ____________ / erhalten am (*) __________________
________________________________________________________
Name des/der Verbraucher(s)
________________________________________________________
Anschrift des/der Verbraucher(s)
________________________________________________________
Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
_________________________
Datum
(*) Unzutreffendes streichen
`}</code></pre>
    </Stack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      